<template>
  <section>
    <v-container fluid>
      <v-row>
        <v-col md="12">
          <PageHeader :title="headercontent.title" />
        </v-col>
        <v-col md="8">
          <v-form
            ref="form"
            v-model="valid"
            lazy-validation
            class="form-section"
          >
            <v-row>
              <v-col cols="12">
                <v-text-field
                  v-model="title"
                  outlined
                  label="Training Category"
                  class="mr-btm-minus"
                ></v-text-field>
              </v-col>

              <v-col cols="6">
                <v-select
                  v-model="status"
                  :items="statuses"
                  label="Status"
                  outlined
                  item-text="name"
                  item-value="status"
                ></v-select>
              </v-col>

              <v-col cols="12">
                <v-btn class="mb-5 primary" @click="addAttribute">
                  <v-icon> mdi-plus </v-icon>
                </v-btn>

                <div
                  class="repeaterBox"
                  v-for="(data, index) in attributes"
                  :key="index"
                >
                  <div class="removeButton" @click="removeAttribute(index)">
                    <v-icon color="white"> mdi-close </v-icon>
                  </div>
                  <v-text-field
                    v-model="data.attributeKey"
                    outlined
                    label="Title"
                  ></v-text-field>
                  <v-textarea
                    v-model="data.attributeDesc"
                    outlined
                    label="Description"
                  ></v-textarea>
                </div>
              </v-col>

              <v-col cols="12" class="submitbtn">
                <v-btn
                  class="submitbutton btn-dark"
                  @click="validate"
                  :loading="loading"
                >
                  {{ headercontent.title }}
                </v-btn>
              </v-col>
            </v-row>
          </v-form>
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="snackbar" color="success" bottom>
      Training Category Added Successfully
    </v-snackbar>
  </section>
</template>

<script>
import PageHeader from "@/components/dashboard/PageHeader";
import { mapActions, mapGetters } from "vuex";
export default {
  name: "AddTrainingCategory",
  components: { PageHeader },
  data: () => ({
    valid: true,
    title: "",
    id: null,
    attributes: [],
    loading: false,
    headercontent: {
      title: "Add Training",
      addlink: "/dashboard/trainingCategory/add",
      downloadlink: true,
    },
    statuses: [
      { name: "Yes", status: true },
      { name: "No", status: false },
    ],
    status: true,
    snackbar: false,
  }),
  methods: {
    ...mapActions(["createTrainingCategory", "geSingleTrainingCategory"]),
    async validate() {
      if (this.$refs.form.validate()) {
        this.loading = true;
        if (this.$route.params.id) {
          await this.createTrainingCategory({
            title: this.title,
            attributes: this.attributes,
            status: this.status,
            _id: this.$route.params.id,
          });
        } else {
          await this.createTrainingCategory({
            title: this.title,
            attributes: this.attributes,
            status: this.status,
          });
        }
        this.snackbar = true;
        this.$router.push("/dashboard/trainingCategory");
        this.loading = false;
      }
    },

    addAttribute() {
      this.attributes.push({
        attributeKey: "",
        attributeDesc: "",
      });
    },
    removeAttribute(index) {
      this.attributes.splice(index, 1);
    },
  },
  computed: {
    ...mapGetters(["allTrainingCategory"]),
  },
  async created() {
    if (this.$route.params.id) {
      await this.geSingleTrainingCategory(this.$route.params.id);
      this.title = this.allTrainingCategory.results.title;
      this.attributes = this.allTrainingCategory.results.attributes;
      this.status = this.allTrainingCategory.results.status;
      this.id = this.allTrainingCategory.results.imageUrl;
    }
  },
};
</script>

<style lang="scss" scoped>
@import "@/assets/scss/variables.scss";
.btn-dark {
  background: $tagcolor !important;
  color: $white;
  width: 250px;
  min-height: 50px;
}

.imagebox {
  display: flex;
  max-width: 30%;
  // background: rgb(248, 248, 248);
  align-items: center;
  justify-content: space-between;
  margin-bottom: 20px;
  padding: 20px;
  margin-top: 20px;
  label {
    display: flex;
    flex-flow: column;
    justify-content: center;
    align-items: center;
  }
}
.imageold {
  width: 200px;
}
.repeaterBox {
  display: flex;
  flex-direction: column;
  border: 1px solid #ccc;
  padding: 15px;
  margin-bottom: 10px;
  position: relative;
  .removeButton {
    position: absolute;
    right: -25px;
    top: -25px;
    height: 40px;
    width: 40px;
    border-radius: 50%;
    background: red;
    display: flex;
    align-items: center;
    justify-content: center;
  }
}
</style>
